














































import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import Client from "@/models/Client";
import Address from "@/models/Address";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ClientService from "@/services/ClientService";
import AddressService from "@/services/AddressService";
import StringTool from "@/services/tool/StringTool";
import LangModule from "@/store/LangModule";

@Component
export default class ClientDialog extends Vue {
	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	@Prop() newClient!: any
	@Prop({ default: false }) readonly isPotential!: boolean
	client: Client = new Client()
	address: Address = new Address()

	email: string = ""
	phone: string = ""

	emailRules = [
		(v: string) => v && v.length > 0 ? true : this.lang.emailRequired,
		(v: string) => StringTool.validateEmail(v) ? true : this.lang.emailNoValid
	]
	basicRules = [
		(input: string | undefined) => (input != undefined ? true : this.lang.pleaseFillField),
		(input: string  | undefined) => input != undefined && input.length >= 4 ? true : this.lang.fieldMin4Characters,
		(input: string  | undefined) => input != undefined && input.length <= 256 ? true : this.lang.fieldMax256Characters,
	]
	titleRules = [(v: string) => v && v.length >= 2 ? true : this.lang.fieldMin2Characters]

	closeDialog() {
		this.$emit("close")
	}

	refresh() {
		this.$emit("refresh")
	}

	createClient() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				this.lang.warning,
				this.lang.createClientQuestion,
				async () => {
					this.client.email = this.email
					this.address.email = this.email
					this.client.phone = this.phone
					this.client.isPotential = this.isPotential
					this.address.phone = this.phone

					await ClientService.postClient(this, this.client)
					// await AddressService.postAddress(this, this.address, this.client.id!)
					await this.closeDialog()
					await this.refresh()
				}
			))
		}
	}
}
