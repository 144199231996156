import {JsonConverter, JsonCustomConvert, JsonObject, JsonProperty} from "json2typescript"
import Auditing from "@/models/Auditing"
import Address from "@/models/Address"
import OrderDetail from "@/models/OrderDetail";
import BudgetDetail from "@/models/BudgetDetail";
import Client from "@/models/Client";
import PotentialClient from "@/models/PotentialClient";

export enum BudgetType {
    SEND = "SEND",
    FINISH = "FINISH",
    DRAFT = "DRAFT",
}

@JsonConverter
class BudgetConverter implements JsonCustomConvert<BudgetType> {
    deserialize(data: string): BudgetType {
        return (<any>BudgetType)[data]
    }
    serialize(data: BudgetType): any {
        return data.toString()
    }
}

@JsonObject("Budget")
export default class Budget extends Auditing {
    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("reference", String, true)
    reference?: string = undefined
    @JsonProperty("description", String, true)
    description?: string = undefined
    @JsonProperty("budgetDetails", [BudgetDetail], true)
    budgetDetails?: BudgetDetail[] = []
    @JsonProperty("shippingAddress", Address, true)
    shippingAddress?: Address = undefined
    @JsonProperty("billingAddress", Address, true)
    billingAddress?: Address = undefined
    @JsonProperty("potentialClient", PotentialClient, true)
    potentialClient?: PotentialClient = undefined
    @JsonProperty("budgetType", BudgetConverter, true)
    budgetType?: BudgetType = undefined
}