import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import SnackbarModule from "@/store/SnackbarModule";
import Client from "@/models/Client";
import JsonTool from "@/services/tool/JsonTool";
import Address from "@/models/Address";
import axios, {AxiosError, AxiosResponse} from "axios";
import Order, {OrderType} from "@/models/Order";
import LangModule from "@/store/LangModule";
import Response from "@/models/responses/Response";

const langModule = getModule(LangModule)

export default class ClientService {

    static async postClient(component: Vue, client: Client) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.post(ConstantTool.BASE_URL + "/api/clients/", client, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })

            // @ts-ignore
            component.client.id = JsonTool.jsonConvert.deserializeObject(response.data, Client).id

            // @ts-ignore
            component.loading = false
            // @ts-ignore
            component.refresh()
            // @ts-ignore
            component.closeDialog()
            getModule(SnackbarModule).makeToast("El cliente ha sido creado")
        } catch (err) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Error al crear el cliente: " + err.response.data.message)
        }
    }

    static async getClient2(id: number): Promise<Response<Client>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/@me/clients/" + id, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const client: Client = JsonTool.jsonConvert.deserializeObject(response.data, Client)
            return Promise.resolve({ result: client })
        } catch (e) { return Promise.reject(e) }
    }

    static async getClients2(page: number, size: number, search: Nullable<string>, all: boolean = false, enabled: boolean = true) {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/@me/clients", {
                params: { page, size, search, all, active: enabled },
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const clients = JsonTool.jsonConvert.deserializeArray(response.data, Client)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: clients, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }

    static async deleteClient(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            console.log(id)
            await component.axios.delete(ConstantTool.BASE_URL + "/api/@me/clients/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            component.$router.push("/clients")
            // @ts-ignore
            component.loading = false;
            getModule(SnackbarModule).makeToast("El cliente ha sido eliminado de manera exitosa!")
        } catch (err) {
            console.log(err)
            // @ts-ignore
            component.loading = false;
            getModule(SnackbarModule).makeToast("No se han eliminar el cliente")
        }
    }

    static async activateClient(component: Vue, id: number, enable: boolean) {
        let formData = new FormData()
        formData.set("active", enable ? "true" : "false")
        try {
            await component.axios.patch(ConstantTool.BASE_URL + "/api/@me/clients/"+ id +"/activate", formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.refresh()
        } catch (err) {
            console.log(err)
            // @ts-ignore
            component.loading = false;
            getModule(SnackbarModule).makeToast("No se han cambiar el estado del cliente")
        }
    }

    static async getMyClientCSV(component: Vue, page: number, size: number, search: string, all: boolean = false, active: boolean = true) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/clients/csv", {
                params: { page, size, search, all, active },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const blob = new Blob([ response.data ], { "type" : "text/csv" })
            let link = document.createElement('a')
            // @ts-ignore
            link.href = window.URL.createObjectURL(blob)
            link.download = 'client.csv'
            // @ts-ignore
            link.click()
        } catch(e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener los clientes.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    /** Traer y filtrar clientes propios.
     *
     * Este endpoint esta pensado para ser usado por los comerciales.
     *
     * @param page Pagina de la busqueda.
     * @param size Tamaño de la busqueda.
     * @param search Texto a filtrar en la busqueda.
     */
    static async getMyOwnClients(page: number, size: number, search: Nullable<string>) {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/@me/clients", {
                headers: {Authorization: getModule(SessionModule).session.token},
                params: { page, size, search }
            })
            const clients = JsonTool.jsonConvert.deserializeArray(response.data, Client)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: clients, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }

    /** Traer y filtrar clientes.
     *
     * Este endpoint esta pensado para ser usado por el distribuidor o por un rol superior.
     *
     * @param page Pagina de la busqueda.
     * @param size Tamaño de la busqueda.
     * @param search Texto a filtrar en la busqueda.
     * @param commercialId Id del comercial (tambien puede ser el id del distribuidor).
     * @param companyId Id de la empresa (*si un distribuidor hace la consulta, este valor se reemplazará por su respectiva empresa desde el servidor*).
     */
    static async getClientsUltimate(page: number, size: number, search: Nullable<string>, commercialId: Nullable<number>, companyId: Nullable<number>) {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + `/api/client`, {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { page, size, search, commercialId, companyId }
            })
            const clients = JsonTool.jsonConvert.deserializeArray(response.data, Client)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: clients, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }

    static async patchClient(id: number, request: Client): Promise<Response<Client>> {
        try {
            const response = await axios.patch(ConstantTool + "/api/@me/clients/" + id, request,{
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const clients = JsonTool.jsonConvert.deserializeObject(response.data, Client)
            return Promise.resolve({ result: clients })
        } catch (e) { return Promise.reject(e) }
    }

    static async setEnabled(id: number, enabled: boolean) {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + `/api/@me/clients/${id}/enabled`, null,{
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { enabled }
            })
            const clients = JsonTool.jsonConvert.deserializeObject(response.data, Client)
            return Promise.resolve({ result: clients })
        } catch (e) { return Promise.reject(e) }
    }
}