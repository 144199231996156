<template>
	<v-treeview
			v-model="selection"
			:items="items"
			open-all
			selectable
			selection-type="independent"
			transition
	>
		<template v-slot:append="{ item, open }">
			<template v-if="showButtons(item)">
				<v-btn text @click="selectAllChilds(item, true)">
					+
				</v-btn>
				<v-btn text @click="selectAllChilds(item, false)">
					-
				</v-btn>
			</template>
		</template>
	</v-treeview>
</template>
<script>
export default {
	name: 'TestTreeview',
	/*
	 * ... Your Vue code here ...
	 */
	data () {
		return {
			selection: [],
			items: [
				{
					id       : 1,
					name     : 'John Dahlback',
					children : [
						{
							id       : 2,
							name     : 'Mario Rossi',
							children : [
								{
									id       : 3,
									name     : 'Super user',
									children : [
										{
											id   : 4,
											name : 'Item 1',
										},
										{
											id   : 5,
											name : 'Item 2',
										},
										{
											id   : 6,
											name : 'Item 3',
										},
										{
											id   : 7,
											name : 'Item 4',
										},
										{
											id   : 8,
											name : 'Item 5',
										},
										{
											id   : 9,
											name : 'Item 6',
										},
									],
								},
							],
						},
						{
							id       : 4,
							name     : 'John Doe',
							children : [],
						},
					],
				},
			],
		}
	},
	methods: {
		showButtons (item) {
			return item.children != null && item.children.length > 0
		},
		selectAllChilds (item, shouldSelect) {
			// Get all children Ids (extract from object)
			const childIds = item.children.map((item) => item.id)
			console.debug('childs ids: ', childIds)
			// Set selection to childs + root item Id
			const rootAndChilds = [ ...childIds, item.id ]
			if (shouldSelect) {
				// Select by keeping existing selection
				console.info('existing sel: ', this.selection)
				console.info('adding: ', rootAndChilds)
				const finalSelection = [ ...this.selection, ...rootAndChilds ]
				console.info('final selection: ', finalSelection)
				this.selection = finalSelection
			}
			else {
				// Remove
				const difference = this.selection.filter(x => !rootAndChilds.includes(x))
				console.info('difference: ', difference)
				this.selection = difference
				// this.selection.filter(it=> !this.selection.includes(rootAndChilds))
			}
		},
	},
	/*
	 * ... Your Vue code here ...
	 */
}
</script>