import {JsonObject, JsonProperty} from "json2typescript"
import Product from "@/models/Product"

@JsonObject("BudgetDetail")
export default class BudgetDetail {
    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("title", String, true)
    title?: string = undefined
    @JsonProperty("date", String, true)
    date?: string = undefined
    @JsonProperty("hour", String, true)
    hour?: string = undefined
    @JsonProperty("liquidated", Boolean, true)
    liquidated?: boolean = undefined
    @JsonProperty("quantity", Number, true)
    quantity?: number = undefined
    @JsonProperty("product", Product, true)
    product?: Product = undefined
}