import {JsonObject, JsonProperty} from "json2typescript";
import Product from "@/models/Product";

@JsonObject("OrderDetail")
export default class OrderDetail {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("connectorProductId", String, true)
    connectorProductId?: string = undefined
    @JsonProperty("connectorOrderId", String, true)
    connectorOrderId?: string = undefined
    @JsonProperty("title", String, true)
    title?: string = undefined
    @JsonProperty("date", String, true)
    date?: string = undefined
    @JsonProperty("hour", String, true)
    hour?: string = undefined
    @JsonProperty("liquidated", Boolean, true)
    liquidated?: boolean = undefined
    @JsonProperty("quantity", Number, true)
    quantity?: number = undefined
    @JsonProperty("unitPrice", String, true)
    unitPrice?: string = undefined
    @JsonProperty("unitPricePvp", String, true)
    unitPricePvp?: string = undefined
    @JsonProperty("totalPvp", String, true)
    totalPvp?: string = undefined
    @JsonProperty("total", String, true)
    total?: string = undefined
    @JsonProperty("product", Product, true)
    product?: Product = undefined

}
