










































import Product from "@/models/Product"
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"
import { getModule } from "vuex-module-decorators"
import SnackbarModule from "@/store/SnackbarModule"
import SessionModule from "@/store/SessionModule"
import LangModule from "@/store/LangModule";

@Component
export default class ProductListComponent extends Vue {

	@Prop() readonly product!: Product
	@Prop() readonly addMode!: boolean
	@Prop() readonly disabled?: boolean
	@Prop() readonly isPvp?: boolean

	lang: any = getModule(LangModule).lang
	sessionModule: SessionModule = getModule(SessionModule)
	isDistributor = this.sessionModule.session.authorities.map(e => e.title == "distributor")[0]
  BASE_URL = ConstantTool.BASE_URL
  image: string = ""
	amount: number = 0

	get rateId(): number { return this.sessionModule.session.user.rateId! }

	get price() { return Number(this.product.rates![this.rateId! - 1].price).toFixed(2) }
	get pricePvp() { return Number(this.product.commercialRate!).toFixed(2) }
	get	total() { return (Number(this.product.rates![this.rateId! - 1].price) * (this.product!.quantity!)).toFixed(2) }
	get totalPvp() { return Number(this.product.commercialRate! * this.product!.quantity!).toFixed(2) }

	//cartModule: CartModule = getModule(CartModule)

  addProduct() {
		this.$emit('openProductDialog', this.product)
	}
	
	remove() {
	this.$emit('remove')
 }

	created() {
		this.refresh()
	}

	errorHandler() {
		this.image = "../assets/ban.svg"
		this.$forceUpdate()
  }

  refresh() {
		this.image = `${this.BASE_URL}/storage/ftp/art_${this.product.connectorId?.toLowerCase()}_1_tmb.jpg`

  }

  @Watch('product')
  onProductChanged() {
		this.refresh()
	}

	@Watch('product.quantity')
	onQuantityChanged() {
		this.$emit("quantity", this.product)
	}

	log(v: any) {
		console.log(v)
	}
}
