import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import ProductCategory from "@/models/ProductCategory";
import SnackbarModule from "@/store/SnackbarModule";
import Collection from "@/models/Collection";

export default class ProductCategoryService {

    static async getProductCategoriesTest(component: Vue, productCategories: ProductCategory[]) {
		(<any>component).loading = true
		try {

			const response: any = await component.axios.get(ConstantTool.BASE_URL + "/public/product-categories")
			let list = JsonTool.jsonConvert.deserializeArray(response.data, ProductCategory)

			// Create root for top-level node(s)
			const root: ProductCategory[] = []
            productCategories.splice(0, productCategories.length)
			  
			list.forEach(productCategory => {

				if (productCategory.parent == null) return root.push(productCategory)
				
				// Insert node as child of parent in flat array
				const parentIndex = list.findIndex(el => el.id === productCategory.parent?.id)
				if (!list[parentIndex].children || list[parentIndex].children?.length == 0) {
					return list[parentIndex].children = [productCategory];
				}
				
				list[parentIndex].children?.push(productCategory);
			})

			  root.forEach(pc => productCategories.push(pc))
			
		} catch (error) {
			
		} finally {
            (<any>component).loading = false
        }
    }


    static async postProductCategory(component: Vue, productCategory: ProductCategory) {
        let formData = new FormData()
        formData.set("name", productCategory.name!)
        formData.set("code", productCategory.code!)
        if (productCategory.parent) formData.set("parentId", productCategory.parent!.id!.toString())
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.post(ConstantTool.BASE_URL + "/api/product-categories",
                formData, {
                    headers: {Authorization: getModule(SessionModule).session.token}
                })

            let item = JsonTool.jsonConvert.deserializeObject(response.data, ProductCategory);
            // @ts-ignore
            component.refresh()
            // @ts-ignore
            component.dialog = false
            // @ts-ignore
            console.log(component.dialog)
            // @ts-ignore
            component.loading = false
        } catch (err) {
            getModule(SnackbarModule).makeToast("No se pudo crear la familia de productos")
            // @ts-ignore
            component.loading = false
        }
    }

    static getProductCategories(component: Vue, productCategories: ProductCategory[], filterParent: boolean = false) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/public/product-categories", {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let list = JsonTool.jsonConvert.deserializeArray(response.data, ProductCategory)
                productCategories.splice(0, productCategories.length)
                if (filterParent) {
                    list.forEach(pc => {
                        if (pc.parent == null) productCategories.push(pc)
                    })
                } else {
                    list.forEach(pc => productCategories.push(pc))
                }
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se han podido obtener las familias de productos"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static getProductCategory(component: Vue, productCategoryId: number) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/public/product-categories/" + productCategoryId, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                // @ts-ignore
                component.productCategory = JsonTool.jsonConvert.deserializeObject(response.data, ProductCategory)
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo obtener las familia de productos"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static patchProductCategory(component: Vue, productCategory: ProductCategory, image: File | null = null) {

        let formData: FormData = new FormData()

        formData.set("name", productCategory.name!)
        formData.set("code", productCategory.code!)
        formData.set("description", productCategory.description!)
        formData.set("position", productCategory.position!.toString())

        if (productCategory.parent) formData.set("parentId", productCategory.parent!.id!.toString())
        if (image) formData.set("image", image)
        // @ts-ignore
        component.loading = true
        component.axios.patch(ConstantTool.BASE_URL + "/api/product-categories/" + productCategory.id!,
            formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(response => {
                // @ts-ignore
                component.refresh()
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo actualizar la familia de productos"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static async deleteProductCategory(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.delete(ConstantTool.BASE_URL + "/api/product-categories/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.loading = false
            // @ts-ignore
            component.$router.push("/product-categories")
            getModule(SnackbarModule).makeToast("Categoria eliminada exitosamente")
        } catch (err) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("No se ha podido eliminar la categoria")
        }
    }

}
